<template>
  <div class="accounts-page">
    <div v-if="$system.user.isAuthenticated" class="container">
      <!--Page title-->
      <Title :title="title" />
      <!--Search-->
      <div class="row">
        <b-input-group size="sm" class="mb-3 col-12 col-lg-3 p-0">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Enter search text"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="col-2 col-lg-3 p-0 mb-3">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-10 col-lg-1 offset-lg-5 mb-3">
          <div class="flex-end">
            <b-icon-arrow-counterclockwise
              @click="getAccounts"
              class="btn__refresh"
              v-b-tooltip.hover
              title="Refresh"
            />
          </div>
        </div>
      </div>
      <!--Accounts-->
      <div v-if="!loading" class="row">
        <b-table
          class="table"
          hover
          striped
          :items="accounts"
          :fields="fieldsAccounts"
          :filter="filter"
          :filter-included-fields="filterOn"
          responsive
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!--Account Router Link-->
          <template #cell(Name)="row">
            <div>
              <router-link
                :to="'/accounts/' + row.item.Id"
                v-b-tooltip.hover
                title="Account information"
                class="active-link-table"
              >
                {{ row.item.Name }}
              </router-link>
            </div>
          </template>
          <!--Account Phone-->
          <template #cell(Phone)="row">
            <div>
              <a class="active-link-table" :href="'tel:' + row.item.Phone">{{ row.item.Phone }}</a>
            </div>
          </template>
          <!--Account Email-->
          <template #cell(Email)="row">
            <div>
              <a class="active-link-table" :href="'mailto:' + row.item.Email">{{ row.item.Email }}</a>
            </div>
          </template>
        </b-table>

        <div v-if="!accounts.length" class="col-12 flex-center">
          <p class="text-center p-5">No Accounts found.</p>
        </div>
        <!--Table Pagination-->
        <div class="col-12 flex-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
      <div v-else class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './AccountsPage.scss'
import AccountsService from '@/services/accounts/accounts.service'
import Title from '@/components/main-layout/title/Title.vue'
export default {
  name: 'AccountsPage',
  components: {
    Title,
  },

  metaInfo: {
    title: 'Accounts',
    titleTemplate: '%s | Merchant Portal | Nexi ',
  },
  data() {
    return {
      loading: false,
      title: 'Accounts',
      accounts: [],
      fieldsAccounts: [
        {
          key: 'Name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'DBA',
          label: 'DBA',
          sortable: true,
        },
        {
          key: 'StageName',
          label: 'Stage Name',
          sortable: true,
        },
        {
          key: 'Phone',
          label: 'Phone',
          sortable: true,
        },
        {
          key: 'Email',
          label: 'Email',
          sortable: true,
        },
      ],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 60, { value: 1000, text: 'Show all' }],
    }
  },
  computed: {
    auth() {
      return this.$system.user.isAuthenticated
    },
  },
  watch: {
    auth() {
      this.getAccounts()
    },
  },
  created() {
    if (this.$system.user.isAuthenticated) {
      this.getAccounts()
    } else this.$system.firstLoading()
  },
  methods: {
    async getAccounts() {
      try {
        this.loading = true
        const response = await AccountsService.getAccounts(this.$system.user)
        if (
          response &&
          response.status == 'success' &&
          response.body &&
          response.body.data
        ) {
          this.accounts = response.body.data
          this.totalRows = this.accounts.length
          this.loading = false
        } else {
          this.loading = false
        }
      } catch (err) {
        this.loading = false
      }
    },
  },
}
</script>
