import Axios from '../axios-instance/axios.service';

const API_URL =
  process.env.NODE_ENV !== 'development' ? `${window.location.origin}/api/` : 'http://localhost:5000/api/';

class AccountsService {
  async getAccounts(user) {
    const { data } = await Axios.post(`${API_URL}accounts`, user);
    return data;
  }
}

export default new AccountsService();